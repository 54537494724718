/* eslint-disable no-unused-expressions */
/**
 *
 * @author Nitesh Vaidya
 * @class Composition
 * @version 1.0
 * @inheritDoc
 */
import i18n from './i18n.json';
export default class BookingEngineComposition extends CoreJS.BaseComponent {
  static PARENT_NAMESPACE = 'ace-core-booking-engine';
  static CLASS_NAMESPACE = 'ace-core-booking-engine__guests';

  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
  }

  /** @inheritDoc */
  initialize() {
    this.compositionHooks();
    this.bindCompositionEvents();
    this.config = {
      room: {
        min: 1,
        max: parseInt(this.componentHost?.getAttribute('data-max-room'))
      },
      adult: {
        min: 1,
        max: parseInt(this.componentHost?.getAttribute('data-max-adult'))
      },
      child: {
        min: 0,
        max: parseInt(this.componentHost?.getAttribute('data-max-child'))
      },
      maxChildAge: parseInt(this.componentHost?.getAttribute('data-max-child-age')
      ),
      pax: {
        max: parseInt(this.componentHost?.getAttribute('data-max-pax'))
      }
    };
    this.guestDetails = new Map();
    const bodySelector = document.body;
    if (bodySelector && bodySelector.classList.contains('offer-page')) {
      const posSiteCode = JSON.parse(bodySelector.dataset.posHandler);
      if (posSiteCode.siteCode === 'sofitel') {
        this.onLoadRoomAdultChildMarkup();
      }
    }
  }
  /**
   * Onload rooms adult change
   */
  onLoadRoomAdultChildMarkup() {
    const minRooms = this.componentHost?.dataset.minRoom ? parseInt(this.componentHost?.dataset.minRoom) : 1;
    const minAdults = this.componentHost?.dataset.minAdult ? parseInt(this.componentHost?.dataset.minAdult) : 1;
    const minChilds = this.componentHost?.dataset.minChild ? parseInt(this.componentHost?.dataset.minChild) : 0;
    const dropdownContent = this.componentHost.querySelector('.ace-core-booking-engine__dropdown--content');
    const addRoom = dropdownContent.querySelector('.ace-counter__plus.addButton');
    for (let i = 1; i < minRooms; i++) {
      this.onClickLoadMarkup(addRoom);
    }
    const rooms = dropdownContent.querySelectorAll('.ace-room');
    rooms.forEach((item) => {
      const addButtons = item.querySelectorAll('.ace-counter__plus:not(.addButton)');
      for (let i = 1; i < minAdults; i++) {
        this.onClickLoadMarkup(addButtons[0]);
      }
      for (let i = 0; i < minChilds; i++) {
        this.onClickLoadMarkup(addButtons[1], true);
      }
    });
  }
  /**
   * element selector hooks
   */
  compositionHooks() {
    this.compositionInput = this.componentHost.querySelector(
      `.${BookingEngineComposition.CLASS_NAMESPACE} .input`
    );
    this.compositionDropdown = this.componentHost.querySelector(
      `.${BookingEngineComposition.PARENT_NAMESPACE}__dropdown`
    );
    this.compositionsCountersButtons = this.componentHost.querySelectorAll(
      '.ace-counter .ace-counter__buttons button'
    );
  }

  /**
   * Open composition dropdown
   */
  openCompositionDropdownHandler() {
    this.compositionDropdown.classList.add('show');
    this.componentHost.closest(`.${BookingEngineComposition.CLASS_NAMESPACE}`).classList.add('open');
    this.compositionInput.focus();
    this.compositionInput.setAttribute('aria-expanded', true);
    this.compositionDropdown.setAttribute('aria-hidden', false);
    if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      this.compositionDropdown.querySelector('.ace-core-booking-engine__dropdown--header').style.display = '';
      this.compositionDropdown.querySelector('.ace-core-booking-engine__dropdown--footer').style.display = '';
      document.querySelector('body').style.height = 0;
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('html').style.overflow = 'hidden';
      setTimeout(() => {
        this.compositionDropdown.scrollTo(0, 0);
      }, 100);
    }
  }
  /**
   * close composition dropdown
   */
  closeCompositionDropdownHanlder() {
    if (this.compositionDropdown.classList.contains('show')) {
      this.compositionDropdown.classList.remove('show');
      this.componentHost.closest(`.${BookingEngineComposition.CLASS_NAMESPACE}`).classList.remove('open');
      this.compositionInput.focus();
      this.compositionInput.setAttribute('aria-expanded', false);
      this.compositionDropdown.setAttribute('aria-hidden', true);
      if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        this.compositionDropdown.querySelector('.ace-core-booking-engine__dropdown--header').style.display = 'none';
        this.compositionDropdown.querySelector('.ace-core-booking-engine__dropdown--footer').style.display = 'none';
        document.querySelector('body').style.height = '';
        document.querySelector('body').style.overflow = '';
        document.querySelector('html').style.overflow = '';
      }
    }
  }

  /**
   * populate child age select elements
   * @param { HTML } dropdownSelect
   */
  populateChildAgeOptions(dropdownSelect) {
    for (let i = 0; i <= this.config.maxChildAge; i++) {
      const opt = new Option(i, i);
      dropdownSelect.add(opt, undefined);
    };
  };

  /**
   * Escape key handler
   */
  escapeKeyHandler() {
    this.componentHost.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      if (
        event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.esc &&
        this.componentHost.querySelector('.ace-dropdown__list.show') === null
      ) {
        this.closeCompositionDropdownHanlder();
      }
    });
  }
  /**
   * Close modal on clicking outside
   */
  outsideClickHandler() {
    window.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      (event) => {
        if (
          !event.target.closest(`.${BookingEngineComposition.CLASS_NAMESPACE}`)
        ) {
          this.closeCompositionDropdownHanlder();
        }
      },
      false
    );
    window.addEventListener(
      CoreJS.DomEventConstants.FOCUS_IN,
      (event) => {
        if (
          !event.target.closest(`.${BookingEngineComposition.CLASS_NAMESPACE}`)
        ) {
          this.closeCompositionDropdownHanlder();
        }
      },
      false
    );
  }

  /**
   *  bind composition input events handler
   */
  bindCompositionInputEvent() {
    if (this.componentHost?.closest('.core-booking-engine__homepagewrapper')) {
      this.componentHost.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.openCompositionDropdownHandler();
      });
      this.compositionDropdown.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
        event.stopPropagation();
      });
    } else {
      this.compositionInput.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.openCompositionDropdownHandler();
      });
    }
    this.compositionInput.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
      if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && event.shiftKey) {
        this.closeCompositionDropdownHanlder();
      }
    });

    this.componentHost.querySelector('.ace-core-booking-engine__dropdown--content')?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
      const targetElement = event.target;
      this.onClickLoadMarkup(targetElement);
    });

    this.compositionDropdown.querySelector('.ace-core-booking-engine__dropdown--header .close')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.closeCompositionDropdownHanlder();
    });

    this.compositionDropdown.querySelector('.ace-core-booking-engine__dropdown--footer .validate')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.closeCompositionDropdownHanlder();
    });
  }

  onClickLoadMarkup(targetElement, onloadEvt = false) {
    // Setting eventlistner to the parent and calling functions based on target event.
    if (targetElement?.classList?.contains('addButton') || targetElement?.classList?.contains('deleteButton')) {
      this.counterHandler(targetElement);
      this.roomChangeHandler(targetElement?.getAttribute('data-counter-type'));
    } else if (targetElement?.parentElement?.classList?.contains('ace-counter__buttons')) {
      if (targetElement?.closest('.ace-counter')?.getAttribute('data-counter') === 'child') {
        this.counterHandler(targetElement);
        this.childrenChangeHandler(targetElement, onloadEvt);
      } else if (targetElement?.closest('.ace-counter')?.getAttribute('data-counter') === 'adult') {
        this.counterHandler(targetElement);
      }
    }
  }

  /*
   *
   * @param {HTML} room
   * @returns {adultCount: Number, childCount: Number, totalCount: Number}
   */
  paxChecker(room) {
    const adultCount = parseInt(
      room?.querySelector('.ace-counter__adults input').value
    );
    const childCount = parseInt(
      room?.querySelector('.ace-counter__children input').value
    );
    return {
      adultCount: adultCount,
      childCount: childCount,
      totalCount: adultCount + childCount
    };
  }

  /**
   * Disabling the counter buttons
   * @param {HTML} counterWrapper
   * @param {plus | minus} counterType
   * @param {boolean} status
   */
  counterDisableHandler(counterWrapper, counterType, status) {
    if (status) {
      counterWrapper
        .querySelector(`.ace-counter__${counterType}`)
        .classList.add('disabled');
      counterWrapper
        .querySelector(`.ace-counter__${counterType}`)
        .setAttribute('aria-disabled', true);
      counterWrapper
        .querySelector(`.ace-counter__${counterType}`)
        .setAttribute('tabindex', '-1');
    } else {
      counterWrapper
        .querySelector(`.ace-counter__${counterType}`)
        .classList.remove('disabled');
      counterWrapper
        .querySelector(`.ace-counter__${counterType}`)
        .removeAttribute('aria-disabled');
      counterWrapper
        .querySelector(`.ace-counter__${counterType}`)
        .removeAttribute('tabindex');
    }
  }

  /*
   * counter value increment or decrement handler
   * @param {increment | decrement} counterType
   * @param {Number} counterValue
   * @return Number
   */
  counterValueUpdate(counterType, counterValue) {
    if (counterType === 'increment') {
      return ++counterValue;
    }
    if (counterType === 'decrement') {
      return --counterValue;
    }
  }
  /*
   * counter controller based on configuration
   * @param {room | adult | child} counterBelongsTo
   * @param {increment | decrement} counterType
   * @param {Number} counterValue
   * @param {HTML} counterWrapper
   * @return null
   */
  counterController(
    counterBelongsTo,
    counterType,
    counterValue,
    counterWrapper
  ) {
    /*
     * @param {Number} Index
     */
    const index = this.counterValueUpdate(counterType, counterValue);
    if (index === this.config[counterBelongsTo].min) {
      this.counterDisableHandler(counterWrapper, 'minus', true);
      this.counterDisableHandler(counterWrapper, 'plus', true);
      if (counterBelongsTo === 'room' && this.config[counterBelongsTo].min !== this.config[counterBelongsTo].max) {
        this.counterDisableHandler(counterWrapper, 'minus', true);
        this.counterDisableHandler(counterWrapper, 'plus', false);
      }
    } else if (index <= this.config[counterBelongsTo].min) {
      this.counterDisableHandler(counterWrapper, 'minus', true);
      this.counterDisableHandler(counterWrapper, 'plus', false);
    } else if (index >= this.config[counterBelongsTo].max) {
      this.counterDisableHandler(counterWrapper, 'minus', false);
      this.counterDisableHandler(counterWrapper, 'plus', true);
    } else if (
      index > this.config[counterBelongsTo].min &&
      index < this.config[counterBelongsTo].max
    ) {
      this.counterDisableHandler(counterWrapper, 'minus', false);
      this.counterDisableHandler(counterWrapper, 'plus', false);
    } else {
      return null;
    }
    counterWrapper.querySelector('input').value = this.counterValueUpdate(
      counterType,
      counterValue
    );
  }

  /**
   * Render guest placeholder
   */
  renderGuest() {
    const search = new Map();
    const toatlRooms = parseInt(
      this.componentHost.querySelector('[data-input="room"]')?.value
    );
    const currentPageLanguage = i18n[document.documentElement.lang] || i18n.en;
    let totalGuest = 0;
    this.componentHost
      .querySelectorAll('.ace-room:not(.hidden) input')
      ?.forEach((input) => {
        totalGuest += parseInt(input.value);
      });

    this.compositionInput.innerHTML = `${toatlRooms} ${currentPageLanguage['$booking.NumRoom']} - ${totalGuest} ${currentPageLanguage['$booking.NumGuest']} `;

    search.set('roomNumber', toatlRooms);
    search.roomCriteria = [];
    this.componentHost.querySelectorAll('.ace-room')?.forEach((room, index) => {
      if (index < toatlRooms) {
        room.querySelectorAll('input').forEach((guest) => {
          switch (guest.dataset.input) {
            case 'adult':
              search.set(
                `roomCriteria[${index}].adultNumber`,
                parseInt(guest.value)
              );
              break;
            case 'child':
              const totalChildren = parseInt(guest.value);
              search.set(
                `roomCriteria[${index}].childNumber`,
                parseInt(guest.value)
              );
              room
                .querySelectorAll('.ace-dropdown__button-text')
                ?.forEach((child, childIndex) => {
                  if (childIndex < totalChildren) {
                    search.set(
                      `roomCriteria[${index}].children[${childIndex}].age`,
                      child.innerHTML
                    );
                  }
                });
              break;
          }
        });
      }
    });
  }

  /**
   * roomChangeHanlder adding and removing rooms
   * @param {string} type determine add or delete
   */
  roomChangeHandler(type) {
    const checkSinglRoom = this.componentHost.querySelector('.deleteWrapper');
    if (type === 'increment') {
      const newRoom = this.getRoomHTML(this.componentHost.querySelector('[data-input="room"]').value - 1);
      this.componentHost.querySelector('.ace-core-booking-engine__dropdown--content.ace-core-booking-engine__dropdown--content--auto .ace-room.removeBorder').insertAdjacentHTML('afterend', newRoom);
      checkSinglRoom.classList.remove('removePadding');
      const roomContainers = this.componentHost.querySelectorAll('.ace-room');
      roomContainers?.forEach((room, i) => {
        if ( i < this.componentHost.querySelector('[data-input="room"]').value) {
          room.querySelector('.ace-counter__plus').focus();
          if (i === this.componentHost.querySelector('[data-input="room"]').value - 1) {
            room.classList.add('removeBorder');
          } else {
            if (room.classList.contains('removeBorder')) {
              room.classList.remove('removeBorder');
            }
          }
          if (room.querySelector('input[data-input="adult"]').value === '') {
            room.querySelector('input[data-input="adult"]').value =
          this.config.adult.min;
          }
        }
      });
      this.renderGuest();
    } else if (type === 'decrement') {
      if (this.componentHost?.querySelector('.deleteButton')?.classList?.contains('disabled')) {
        checkSinglRoom?.classList?.add('removePadding');
      } else {
        checkSinglRoom?.classList?.remove('removePadding');
      }
      const roomContainers = this.componentHost?.querySelectorAll('.ace-room');
      roomContainers?.forEach((room, i) => {
        if ( i < this.componentHost?.querySelector('[data-input="room"]')?.value) {
          room?.classList?.remove('hidden');
          if (i === this.componentHost?.querySelector('[data-input="room"]')?.value - 1) {
            room?.classList?.add('removeBorder');
          }
          if (room?.querySelector('input[data-input="adult"]')?.value === '') {
            room.querySelector('input[data-input="adult"]').value =
                    this.config?.adult?.min;
          }
        }
        if (i === roomContainers?.length - 1) {
          room.remove();
        }
      });
      this.renderGuest();
    }
  }

  /**
   * childrenChangeHanlder adding and removing children
   * @param {Object} targetElement element on which action performed
   * @param {boolean} onloadEvt
   */
  childrenChangeHandler(targetElement, onloadEvt) {
    const roomContainer = targetElement?.closest('.ace-room');
    if (targetElement.getAttribute('data-counter-type') === 'increment') {
      const newChild = document.createElement('div');
      newChild?.classList?.add('ace-dropdown');
      newChild.innerHTML = this.getChildAgeHTML();
      const isBookingEngineV2 = document.querySelector('div.core-booking-engine-v2');
      if (isBookingEngineV2) {
        const labelV2 = newChild.querySelector('label.ui-body-01.ace-dropdown__title');
        if (labelV2) {
          labelV2.className = 'ui-body-02 ace-dropdown__title';
        }
      }
      this.populateChildAgeOptions(newChild?.querySelector('[id^="chiild-dropdown"]'));
      roomContainer?.appendChild(newChild);
      if (onloadEvt) {
        window.dispatchEvent(new CustomEvent(CoreJS.CustomDomEventConstants.EXTRA_DOM_LOADED_EVENT));
      } else {
        document.dispatchEvent(new CustomEvent(CoreJS.CustomDomEventConstants.EXTRA_DOM_LOADED_EVENT));
      }
    } else if (targetElement?.getAttribute('data-counter-type') === 'decrement') {
      roomContainer?.querySelectorAll('.ace-dropdown')?.forEach((object, i) => {
        if (i === roomContainer?.querySelectorAll('.ace-dropdown')?.length - 1) {
          object.remove();
        }
      });
    }
    roomContainer?.querySelectorAll('.ace-dropdown')?.forEach((object, index) => {
      this.setChildrenAttributes(roomContainer, object, index);
    });
  }

  /**
   * Set attribute value for children added
   * @param {html} roomContainer parent room for added child
   * @param {integer} object child on which action performed
   * @param {integer} index room number
   */
  setChildrenAttributes(roomContainer, object, index) {
    const currentPageLanguage = i18n[document.documentElement.lang] || i18n.en;
    if (object?.querySelector('.ace-dropdown__title')) {
      object.querySelector('.ace-dropdown__title').innerHTML = `${currentPageLanguage['$booking.roomsGuest.content']} ${index + 1}`; /* `Child age ${index + 1}`; Will need i18 handling */
    }
    object?.querySelector('.ace-dropdown__title')?.setAttribute('for', `chiild-dropdown-button-${roomContainer?.getAttribute('room-id')}-${index}`);
    object?.querySelector('.ace-dropdown__select')?.setAttribute('id', `chiild-dropdown-button-${roomContainer?.getAttribute('room-id')}-${index}`);
    object?.querySelector('.ace-dropdown__select')?.setAttribute('data-name', `search.roomCriteria[${roomContainer?.getAttribute('room-id')}].children[${index}].age`);
  }

  /**
   *  counterHandler updating the total of rooms, adult and children
   * @param {Object} targetElement element on which action performed
   * @return {integer}
   */
  counterHandler(targetElement) {
    const counterWrapper = targetElement?.closest('.ace-counter');
    if (targetElement?.classList?.contains('disabled')) {
      counterWrapper?.querySelector('button:not(.disabled)')?.focus();
      return 0;
    }
    const counterValue = counterWrapper?.querySelector('input')?.value;
    const counterBelongsTo = counterWrapper?.dataset.counter;
    const { counterType } = targetElement?.dataset;
    this.counterController(
      counterBelongsTo,
      counterType,
      counterValue,
      counterWrapper
    );
    const roomContainer = counterWrapper?.closest('.ace-room');
    const roomContainerGuest = this.paxChecker(roomContainer);
    if (
      roomContainerGuest.totalCount === this.config.pax.max &&
          roomContainer &&
          counterBelongsTo !== 'room'
    ) {
      roomContainer?.querySelectorAll('.ace-counter')?.forEach((counter) => {
        this.counterDisableHandler(counter, 'plus', true);
      });
    } else if (
      roomContainerGuest?.totalCount !== this.config?.pax?.max &&
          roomContainer &&
          counterBelongsTo !== 'room'
    ) {
      roomContainer?.querySelectorAll('.ace-counter')?.forEach((counter) => {
        if (
          this.config[counter.dataset.counter]?.max >
              counter?.querySelector('input')?.value
        ) {
          this.counterDisableHandler(counter, 'plus', false);
        }
      });
    }
    this.renderGuest();
  }

  /**
   * Rooms initialize
   */
  configInitialize() {
    this.componentHost.querySelectorAll('.ace-room')?.forEach((room, index) => {
      if (index === 0) {
        room.classList.remove('hidden');
        room.classList.add('removeBorder');
      }
      room.querySelectorAll('.ace-dropdown__title')?.forEach((title, index) => {
        title.innerHTML = title.innerHTML.replace('[x]', index + 1);
      });
    });

    this.componentHost
      ?.querySelectorAll('.ace-dropdown')
      ?.forEach((dropdown) => {
        dropdown.classList.add('hidden');
      });
  }
  /**
   * childrenDropdownObserver
   */
  childrenDropdownObserver() {
    const listItems = this.componentHost.querySelectorAll(
      '.ace-dropdown__list li'
    );
    const observeDropDown = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.attributeName === 'aria-selected' &&
          mutation.target.getAttribute('aria-selected') === 'true' &&
          mutation.target.classList.contains('selected')
        ) {
          this.renderGuest();
        }
      });
    });
    listItems.forEach((item) => {
      observeDropDown.observe(item, {
        attributes: true
      });
    });
  }
  checkSingleRoom() {
    if (this.componentHost.querySelector('.deleteButton').classList.contains('disabled')) {
      this.componentHost.querySelector('.deleteWrapper').classList.add('removePadding');
    }
  }

  /**
   *
   * Bind events
   */
  bindCompositionEvents() {
    this.checkSingleRoom();
    this.outsideClickHandler();
    this.escapeKeyHandler();
    this.bindCompositionInputEvent();
    this.childrenDropdownObserver();
    this.configInitialize();
    this.renderGuest();
  }

  /**
   * Room HTML
   * @param {integer} id current room index
   * @return {string}
   */
  getRoomHTML(id) {
    const currentPageLanguage = i18n[document.documentElement.lang] || i18n.en;
    const roomHtml =
    `<fieldset class="ace-room" room-id="${id}">
      <legend class="sr-only">${currentPageLanguage['$booking.room.label']} ${id + 1}</legend>
      <span class="ace-room__title ui-label-01">${currentPageLanguage['$booking.room.label']} ${id + 1}</span>
      <div class="ace-counter ace-counter__adults" data-counter="adult">
         <label for="core-booking-engine-room-${id}-adult" class="ace-counter__heading">
         <span class="ace-counter__heading-title ui-body-01-strong">
         ${currentPageLanguage['$booking.adult.label']}
         </span>
         </label>
         <div class="ace-counter__buttons">
            <button class="ace-counter__minus  disabled" tabindex="-1" aria-disabled="true" data-counter-type="decrement" type="button">
            <span aria-hidden="true" class="icon icon-minus-24icon"></span>
            <span class="sr-only">- ${currentPageLanguage['$booking.RemoveAdult']}</span>
            </button>
            <input aria-live="polite" name="search.roomCriteria[${id}].adultNumber" aria-atomic="true" tabindex="-1" data-input="adult"
            class="ui-body-01" id="core-booking-engine-room-${id}-adult" type="text" value="1" readonly="readonly" pattern="[0-9]">
            <button class="ace-counter__plus" data-counter-type="increment" type="button">
            <span aria-hidden="true" class="icon icon-plus-24icon"></span>
            <span class="sr-only">+${currentPageLanguage['$booking.AddAdult']}</span>
            </button>
         </div>
      </div>
      <div class="ace-counter ace-counter__children" data-counter="child">
         <label for="core-booking-engine-room-${id}-children" class="ace-counter__heading">
         <span class="ace-counter__heading-title ui-body-01-strong">
         ${currentPageLanguage['$booking.childrens.label']}
         </span>
         </label>
         <div class="ace-counter__buttons">
            <button class="ace-counter__minus  disabled" tabindex="-1" aria-disabled="true" data-counter-type="decrement" type="button">
            <span aria-hidden="true" class="icon icon-minus-24icon"></span>
            <span class="sr-only">- ${currentPageLanguage['$booking.RemoveChildren']}</span>
            </button>
            <input aria-live="polite" name="search.roomCriteria[${id}].childrenNumber" aria-atomic="true" tabindex="-1" data-input="child"
            class="ui-body-01" id="core-booking-engine-room-${id}-children" type="text" value="0" readonly="readonly" pattern="[0-9]">
            <button class="ace-counter__plus" data-counter-type="increment" type="button">
            <span aria-hidden="true" class="icon icon-plus-24icon"></span>
            <span class="sr-only">+${currentPageLanguage['$booking.AddChildren']}</span>
            </button>
         </div>
      </div>
    </fieldset>`;
    return roomHtml;
  }

  /**
   * getChildAgeHTML
   * @return {string}
   */
  getChildAgeHTML() {
    const childAgeHTML = `<div class="ace-dropdown__container">
    <div class="ace-dropdown__selectbox">
       <label for="chiild-dropdown-button-0-0" class="ui-body-01 ace-dropdown__title">Child age 1
       </label>
       <select id="chiild-dropdown-button-0-0" class="ace-dropdown__select" data-name="search.roomCriteria[0].children[0].age" data-value="-">
          <option value="-">-</option>
       </select>
       <span aria-hidden="true">
          <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="book-now-room__childAge__select__icon-arrow">
             <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 8.46967C5.76256 8.17678 6.23744 8.17678 6.53033 8.46967L12 13.9393L17.4697 8.46967C17.7626 8.17678 18.2374 8.17678
             18.5303 8.46967C18.8232 8.76256 18.8232 9.23744 18.5303 9.53033L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256
             5.46967 8.46967Z"
             fill="#1E1852">
             </path>
          </svg>
       </span>
    </div>
 </div>`;
    return childAgeHTML;
  }
}

CoreJS.BaseComponent.registerComponent(
  BookingEngineComposition.CLASS_NAMESPACE,
  BookingEngineComposition
);
